import { Fragment, useEffect } from "react";
import { useResumeStore } from "src/components/builder/resume";
import { BuilderLayout } from "src/components/builder/artboard/builder";
import { resume } from "./dummy";
import { FirebaseContext } from "src/firebase";
import { useContext, useState } from "react";
import { PrinterLayout } from "src/components/builder/artboard/printer";

export function Print() {
  const { currentUser, token } = useContext(FirebaseContext);
  const [loading, setLoading] = useState(true);
  const setValue = useResumeStore((state) => state.setValue);
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        setValue("userId", currentUser.id);
        const resumeData = await fetch(`https://cc3doyofpqveup6cc4jzlvzt340wvctx.lambda-url.ap-south-1.on.aws/client/resume/details?user_id=${currentUser.id}`).then((res) => res.json());
        const data = resumeData?.users[0]?.resume_config?.id
          ? resumeData?.users[0]?.resume_config
          : resume;
        useResumeStore.setState({ resume: data });
        setValue("userId", currentUser.id);
        console.log("useResumeStore.getState().resume");
        console.log(useResumeStore.getState());
      } catch (error) {
        console.error("Error fetching resume data:", error);
      } finally {
        setLoading(false);
        useResumeStore.temporal.getState().clear();
      }
    }
    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (!useResumeStore.getState()?.resume) return null;

  return (
    <div className="min-h-screen min-w-screen max-w-full rounded-lg ">
      <PrinterLayout />
    </div>
  );
}
