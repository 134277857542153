import { pageSizeMap } from "../utils";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useMemo, useRef } from "react";
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import { MM_TO_PX, Page } from "./page";
// import { useArtboardStore } from "../store/artboard";
import { useResumeStore } from "../resume";
import { useBuilderStore } from "../builder";
import { getTemplate } from "./templates";

export const PrinterLayout = () => {
  const setTransformRef = useBuilderStore((state) => state.setTransformRef);
  const format = useResumeStore((state) => state?.resume?.data?.metadata?.page?.format);
  const layout = useResumeStore((state) => state?.resume?.data?.metadata?.layout);
  const template = useResumeStore((state) => state?.resume?.data?.metadata?.template);

  const Template = useMemo(() => getTemplate(template), [template]);

  if (!template) return null;
  if (!layout) return null;

  return (
    <div className="flex items-center justify-center min-h-screen min-w-screen">
      <div className="max-w-full mx-auto rounded-lg" style={{ width: pageSizeMap[format].width * MM_TO_PX }}>
          {layout.map((columns, pageIndex) => (
            <motion.div
              key={pageIndex}
              layout
              initial={{ opacity: 0, x: -200, y: 0 }}
              animate={{ opacity: 1, x: 0, transition: { delay: pageIndex * 0.3 } }}
              exit={{ opacity: 0, x: -200 }}
            >
              <Page mode="builder" pageNumber={pageIndex + 1}>
                <Template isFirstPage={pageIndex === 0} columns={columns} />
              </Page>
            </motion.div>
          ))}
      </div>
    </div>
  );
};

