import { cn, hexToRgb, isEmptyString, isUrl, linearTransform } from "../utils";
import get from "lodash.get";
import { Fragment } from "react";

import { Picture } from "./picture";
import { useResumeStore } from "../resume";

const Header = () => {
  const basics = useResumeStore((state) => state?.resume?.data?.basics);
  const section = useResumeStore((state) => state?.resume?.data?.sections?.summary);
  const profiles = useResumeStore((state) => state?.resume?.data?.sections?.profiles);
  const primaryColor = useResumeStore((state) => state?.resume?.data?.metadata?.theme?.primary);
  const fontSize = useResumeStore((state) => state?.resume?.data?.metadata?.typography?.font?.size);

  return (
    <div>
      <div
        className="p-custom flex items-center space-x-8"
        style={{ backgroundColor: hexToRgb(primaryColor, 0.2) }}
      >
        <div className="space-y-3">
          <div>
            <div className="text-3xl font-bold">{basics.name}</div>
            <div className="text-base font-medium text-primary-css">{basics.headline}</div>
          </div>

          <div
            dangerouslySetInnerHTML={{ __html: section.content }}
            className="wysiwyg"
            style={{ columns: section.columns }}
          />
        </div>

        <Picture />
      </div>

      <div className="p-custom space-y-3" style={{ backgroundColor: hexToRgb(primaryColor, 0.4) }}>
        <div className="flex flex-wrap items-center gap-x-3 gap-y-0.5 text-sm">
          {basics.location && (
            <div className="flex items-center gap-x-1.5">
              <i className="ph ph-bold ph-map-pin text-primary-css" />
              <div>{basics.location}</div>
            </div>
          )}
          {basics.phone && (
            <div className="flex items-center gap-x-1.5">
              <i className="ph ph-bold ph-phone text-primary-css" />
              <a href={`tel:${basics.phone}`} target="_blank" rel="noreferrer">
                {basics.phone}
              </a>
            </div>
          )}
          {basics.email && (
            <div className="flex items-center gap-x-1.5">
              <i className="ph ph-bold ph-at text-primary-css" />
              <a href={`mailto:${basics.email}`} target="_blank" rel="noreferrer">
                {basics.email}
              </a>
            </div>
          )}
          <Link url={basics.url} />
          {basics.customFields.map((item) => (
            <div key={item.id} className="flex items-center gap-x-1.5">
              <i className={cn(`ph ph-bold ph-${item.icon}`, "text-primary-css")} />
              {isUrl(item.value) ? (
                <a href={item.value} target="_blank" rel="noreferrer noopener nofollow">
                  {item.name || item.value}
                </a>
              ) : (
                <span>{[item.name, item.value].filter(Boolean).join(": ")}</span>
              )}
            </div>
          ))}
        </div>

        {profiles.visible && profiles.items.length > 0 && (
          <div className="flex items-center gap-x-3 gap-y-0.5">
            {profiles.items
              .filter((item) => item.visible)
              .map((item) => (
                <div key={item.id} className="flex items-center gap-x-2">
                  <Link
                    url={item.url}
                    label={item.username}
                    className="text-sm"
                    icon={
                      <img
                        className="ph"
                        width={fontSize}
                        height={fontSize}
                        alt={item.network}
                        src={`https://cdn.simpleicons.org/${item.icon}`}
                      />
                    }
                  />
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

const Rating = ({ level }) => (
  <div className="flex items-center gap-x-1.5">
    {Array.from({ length: 5 }).map((_, index) => (
      <div
        key={index}
        className={cn("h-3 w-6 border-2 border-primary-css", level > index && "bg-primary-css")}
      />
    ))}
  </div>
);

const Link = ({ url, icon, iconOnRight, label, className }) => {
  if (!isUrl(url.href)) return null;

  return (
    <div className="flex items-center gap-x-1.5">
      {!iconOnRight && (icon ?? <i className="ph ph-bold ph-link text-primary-css" />)}
      <a
        href={url.href}
        target="_blank"
        rel="noreferrer noopener nofollow"
        className={cn("inline-block", className)}
      >
        {label ?? (url.label || url.href)}
      </a>
      {iconOnRight && (icon ?? <i className="ph ph-bold ph-link text-primary-css" />)}
    </div>
  );
};

const LinkedEntity = ({ name, url, separateLinks, className }) => {
  return !separateLinks && isUrl(url.href) ? (
    <Link
      url={url}
      label={name}
      icon={<i className="ph ph-bold ph-globe text-primary-css" />}
      iconOnRight={true}
      className={className}
    />
  ) : (
    <div className={className}>{name}</div>
  );
};

const Section = ({
  section,
  children,
  className,
  urlKey,
  levelKey,
  summaryKey,
  keywordsKey,
}) => {
  if (!section.visible || section.items.length === 0) return null;

  return (
    <section id={section.id} className="grid">
      <h4 className="mb-2 border-b border-primary-css text-left font-bold text-primary-css">
        {section.name}
      </h4>

      <div
        className="grid gap-x-6 gap-y-3"
        style={{ gridTemplateColumns: `repeat(${section.columns}, 1fr)` }}
      >
        {section.items
          .filter((item) => item.visible)
          .map((item) => {
            const url = (urlKey && get(item, urlKey)) 
            const level = (levelKey && get(item, levelKey, 0))
            const summary = (summaryKey && get(item, summaryKey, "")) 
            const keywords = (keywordsKey && get(item, keywordsKey, [])) 

            return (
              <div key={item.id} className={cn("space-y-2", className)}>
                <div>{children?.(item)}</div>

                {summary !== undefined && !isEmptyString(summary) && (
                  <div dangerouslySetInnerHTML={{ __html: summary }} className="wysiwyg" />
                )}

                {level !== undefined && level > 0 && <Rating level={level} />}

                {keywords !== undefined && keywords.length > 0 && (
                  <p className="text-sm">{keywords.join(", ")}</p>
                )}

                {url !== undefined && section.separateLinks && <Link url={url} />}
              </div>
            );
          })}
      </div>
    </section>
  );
};

const Experience = () => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.experience);

  return (
    <Section section={section} urlKey="url" summaryKey="summary">
      {(item) => (
        <div>
          <LinkedEntity
            name={item.company}
            url={item.url}
            separateLinks={section.separateLinks}
            className="font-bold"
          />
          <div>{item.position}</div>
          <div>{item.location}</div>
          <div className="font-bold">{item.date}</div>
        </div>
      )}
    </Section>
  );
};

const Education = () => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.education);

  return (
    <Section section={section} urlKey="url" summaryKey="summary">
      {(item) => (
        <div>
          <LinkedEntity
            name={item.institution}
            url={item.url}
            separateLinks={section.separateLinks}
            className="font-bold"
          />
          <div>{item.area}</div>
          <div>{item.score}</div>
          <div>{item.studyType}</div>
          <div className="font-bold">{item.date}</div>
        </div>
      )}
    </Section>
  );
};

const Awards = () => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.awards);

  return (
    <Section section={section} urlKey="url" summaryKey="summary">
      {(item) => (
        <div>
          <div className="font-bold">{item.title}</div>
          <LinkedEntity name={item.awarder} url={item.url} separateLinks={section.separateLinks} />
          <div className="font-bold">{item.date}</div>
        </div>
      )}
    </Section>
  );
};

const Certifications = () => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.certifications);

  return (
    <Section section={section} urlKey="url" summaryKey="summary">
      {(item) => (
        <div>
          <div className="font-bold">{item.name}</div>
          <LinkedEntity name={item.issuer} url={item.url} separateLinks={section.separateLinks} />
          <div className="font-bold">{item.date}</div>
        </div>
      )}
    </Section>
  );
};

const Skills = () => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.skills);

  return (
    <Section section={section} levelKey="level" keywordsKey="keywords">
      {(item) => (
        <div>
          <div className="font-bold">{item.name}</div>
          <div>{item.description}</div>
        </div>
      )}
    </Section>
  );
};

const Interests = () => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.interests);

  return (
    <Section section={section} keywordsKey="keywords" className="space-y-0.5">
      {(item) => <div className="font-bold">{item.name}</div>}
    </Section>
  );
};

const Publications = () => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.publications);

  return (
    <Section section={section} urlKey="url" summaryKey="summary">
      {(item) => (
        <div>
          <LinkedEntity
            name={item.name}
            url={item.url}
            separateLinks={section.separateLinks}
            className="font-bold"
          />
          <div>{item.publisher}</div>
          <div className="font-bold">{item.date}</div>
        </div>
      )}
    </Section>
  );
};

const Volunteer = () => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.volunteer);

  return (
    <Section section={section} urlKey="url" summaryKey="summary">
      {(item) => (
        <div>
          <LinkedEntity
            name={item.organization}
            url={item.url}
            separateLinks={section.separateLinks}
            className="font-bold"
          />
          <div>{item.position}</div>
          <div>{item.location}</div>
          <div className="font-bold">{item.date}</div>
        </div>
      )}
    </Section>
  );
};

const Languages = () => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.languages);

  return (
    <Section section={section} levelKey="level">
      {(item) => (
        <div>
          <div className="font-bold">{item.name}</div>
          <div>{item.description}</div>
        </div>
      )}
    </Section>
  );
};

const Projects = () => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.projects);

  return (
    <Section section={section} urlKey="url" summaryKey="summary" keywordsKey="keywords">
      {(item) => (
        <div>
          <div>
            <LinkedEntity
              name={item.name}
              url={item.url}
              separateLinks={section.separateLinks}
              className="font-bold"
            />
            <div>{item.description}</div>
            <div className="font-bold">{item.date}</div>
          </div>
        </div>
      )}
    </Section>
  );
};

const References = () => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.references);

  return (
    <Section section={section} urlKey="url" summaryKey="summary">
      {(item) => (
        <div>
          <LinkedEntity
            name={item.name}
            url={item.url}
            separateLinks={section.separateLinks}
            className="font-bold"
          />
          <div>{item.description}</div>
        </div>
      )}
    </Section>
  );
};

const Custom = ({ id }) => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.custom[id]);

  return (
    <Section
      section={section}
      urlKey="url"
      summaryKey="summary"
      keywordsKey="keywords"
    >
      {(item) => (
        <div>
          <div>
            <LinkedEntity
              name={item.name}
              url={item.url}
              separateLinks={section.separateLinks}
              className="font-bold"
            />
            <div>{item.description}</div>
            <div>{item.location}</div>
            <div className="font-bold">{item.date}</div>
          </div>
        </div>
      )}
    </Section>
  );
};

const mapSectionToComponent = (section) => {
  switch (section) {
    case "experience": {
      return <Experience />;
    }
    case "education": {
      return <Education />;
    }
    case "awards": {
      return <Awards />;
    }
    case "certifications": {
      return <Certifications />;
    }
    case "skills": {
      return <Skills />;
    }
    case "interests": {
      return <Interests />;
    }
    case "publications": {
      return <Publications />;
    }
    case "volunteer": {
      return <Volunteer />;
    }
    case "languages": {
      return <Languages />;
    }
    case "projects": {
      return <Projects />;
    }
    case "references": {
      return <References />;
    }
    default: {
      if (section.startsWith("custom.")) return <Custom id={section.split(".")[1]} />;

      return null;
    }
  }
};

export const Leafish = ({ columns, isFirstPage = false }) => {
  const [main, sidebar] = columns;

  return (
    <div>
      {isFirstPage && <Header />}

      <div className="p-custom grid grid-cols-2 items-start space-x-6">
        <div className="grid gap-y-4">
          {main.map((section) => (
            <Fragment key={section}>{mapSectionToComponent(section)}</Fragment>
          ))}
        </div>

        <div className="grid gap-y-4">
          {sidebar.map((section) => (
            <Fragment key={section}>{mapSectionToComponent(section)}</Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
