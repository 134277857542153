
import { isEmptyString, isUrl } from "../utils";
import get from "lodash.get";
import { Fragment } from "react";
import { cn } from "src/lib/utils";
import { Picture } from "./picture";
import { useResumeStore } from "../resume";

const Header = () => {
  const basics = useResumeStore((state) => state?.resume?.data?.basics);

  return (
    <div className="grid grid-cols-4 gap-x-6">
      <div className="mt-1 space-y-2 text-right">
        <Picture className="ml-auto" />
      </div>

      <div className="col-span-3 space-y-2">
        <div>
          <div className="text-2xl font-bold">{basics?.name}</div>
          <div className="text-base">{basics?.headline}</div>
        </div>

        <div className="space-y-1 text-sm">
          {basics?.location && (
            <div className="flex items-center gap-x-1.5">
              <i className="ph ph-bold ph-map-pin text-primary-css" />
              <div>{basics?.location}</div>
            </div>
          )}
          {basics?.phone && (
            <div className="flex items-center gap-x-1.5">
              <i className="ph ph-bold ph-phone text-primary-css" />
              <a href={`tel:${basics?.phone}`} target="_blank" rel="noreferrer">
                {basics?.phone}
              </a>
            </div>
          )}
          {basics?.email && (
            <div className="flex items-center gap-x-1.5">
              <i className="ph ph-bold ph-at text-primary-css" />
              <a href={`mailto:${basics?.email}`} target="_blank" rel="noreferrer">
                {basics?.email}
              </a>
            </div>
          )}
          <Link url={basics?.url} />
        </div>

        <div className="flex flex-wrap gap-x-3 text-sm">
          {basics?.customFields.map((item) => (
            <div key={item.id} className="flex items-center gap-x-1.5">
              <i className={cn(`ph ph-bold ph-${item.icon}`, "text-primary-css")} />
              {isUrl(item.value) ? (
                <a href={item.value} target="_blank" rel="noreferrer noopener nofollow">
                  {item.name || item.value}
                </a>
              ) : (
                <>
                  <span className="text-primary-css">{item.name}</span>
                  <span>{item.value}</span>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Summary = () => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.summary);

  if (!section?.visible || isEmptyString(section?.content)) return null;

  return (
    <section id={section?.id} className="grid grid-cols-4 gap-x-6">
      <div className="text-right">
        <h4 className="font-medium text-primary-css">{section?.name}</h4>
      </div>

      <div className="col-span-3">
        <div className="relative">
          <hr className="mt-3 border-primary-css pb-3" />
          <div className="absolute bottom-3 right-0 size-3 bg-primary-css" />
        </div>

        <div
          dangerouslySetInnerHTML={{ __html: section?.content }}
          className="wysiwyg"
          style={{ columns: section?.columns }}
        />
      </div>
    </section>
  );
};

const Link = ({ url, icon, iconOnRight, label, className }) => {
  if (!isUrl(url?.href)) return null;

  return (
    <div className="flex items-center gap-x-1.5">
      {!iconOnRight && (icon ?? <i className="ph ph-bold ph-link text-primary-css" />)}
      <a
        href={url?.href}
        target="_blank"
        rel="noreferrer noopener nofollow"
        className={cn("inline-block", className)}
      >
        {label ?? (url?.label || url?.href)}
      </a>
      {iconOnRight && (icon ?? <i className="ph ph-bold ph-link text-primary-css" />)}
    </div>
  );
};

const LinkedEntity = ({ name, url, separateLinks, className }) => {
  return !separateLinks && isUrl(url?.href) ? (
    <Link
      url={url}
      label={name}
      icon={<i className="ph ph-bold ph-globe text-primary-css" />}
      iconOnRight={true}
      className={className}
    />
  ) : (
    <div className={className}>{name}</div>
  );
};

const Section = ({
  section,
  children,
  urlKey,
  dateKey,
  summaryKey,
  keywordsKey,
}) => {
  if (!section?.visible || section?.items.length === 0) return null;

  return (
    <section id={section?.id} className={cn("grid", dateKey !== undefined && "gap-y-4")}>
      <div className="grid grid-cols-4 gap-x-6">
        <div className="text-right">
          <h4 className="font-medium text-primary-css">{section?.name}</h4>
        </div>

        <div className="col-span-3">
          <div className="relative">
            <hr className="mt-3 border-primary-css" />
            <div className="absolute bottom-0 right-0 size-3 bg-primary-css" />
          </div>
        </div>
      </div>

      {dateKey !== undefined && (
        <div className="grid grid-cols-4 gap-x-6 gap-y-4">
          {section?.items
            .filter((item) => item.visible)
            .map((item) => {
              const url = (urlKey && get(item, urlKey)) 
              const date = (dateKey && get(item, dateKey, "")) 
              const summary = (summaryKey && get(item, summaryKey, "")) 
              const keywords = (keywordsKey && get(item, keywordsKey, [])) 

              return (
                <Fragment key={item.id}>
                  <div className="text-right font-medium text-primary-css">{date}</div>

                  <div className="col-span-3 space-y-1">
                    {children?.(item)}

                    {url !== undefined && section?.separateLinks && <Link url={url} />}

                    {summary !== undefined && !isEmptyString(summary) && (
                      <div dangerouslySetInnerHTML={{ __html: summary }} className="wysiwyg" />
                    )}

                    {keywords !== undefined && keywords.length > 0 && (
                      <p className="text-sm">{keywords.join(", ")}</p>
                    )}
                  </div>
                </Fragment>
              );
            })}
        </div>
      )}

      {dateKey === undefined && (
        <div className="grid grid-cols-4 gap-x-6">
          <div
            className="col-span-3 col-start-2 grid gap-x-6 gap-y-3"
            style={{ gridTemplateColumns: `repeat(${section?.columns}, 1fr)` }}
          >
            {section?.items
              .filter((item) => item.visible)
              .map((item) => {
                const url = (urlKey && get(item, urlKey))
                const summary = (summaryKey && get(item, summaryKey, "")) 
                const keywords = (keywordsKey && get(item, keywordsKey, [])) 

                return (
                  <div key={item.id}>
                    {children?.(item)}

                    {url !== undefined && section?.separateLinks && <Link url={url} />}

                    {summary !== undefined && !isEmptyString(summary) && (
                      <div dangerouslySetInnerHTML={{ __html: summary }} className="wysiwyg" />
                    )}

                    {keywords !== undefined && keywords.length > 0 && (
                      <p className="text-sm">{keywords.join(", ")}</p>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </section>
  );
};

const Profiles = () => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.profiles);
  const fontSize = useResumeStore((state) => state?.resume?.data?.metadata?.typography?.font?.size);

  return (
    <Section section={section}>
      {(item) => (
        <div>
          {isUrl(item.url?.href) ? (
            <Link
              url={item.url}
              label={item.username}
              icon={
                <img
                  className="ph"
                  width={fontSize}
                  height={fontSize}
                  alt={item.network}
                  src={`https://cdn.simpleicons.org/${item.icon}`}
                />
              }
            />
          ) : (
            <p>{item.username}</p>
          )}
          {!item.icon && <p className="text-sm">{item.network}</p>}
        </div>
      )}
    </Section>
  );
};

const Experience = () => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.experience);

  return (
    <Section section={section} urlKey="url" dateKey="date" summaryKey="summary">
      {(item) => (
        <div>
          <LinkedEntity
            name={item.company}
            url={item.url}
            separateLinks={section?.separateLinks}
            className="font-bold"
          />
          <div>{item.position}</div>
          <div>{item.location}</div>
        </div>
      )}
    </Section>
  );
};

const Education = () => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.education);

  return (
    <Section section={section} urlKey="url" dateKey="date" summaryKey="summary">
      {(item) => (
        <div>
          <LinkedEntity
            name={item.institution}
            url={item.url}
            separateLinks={section?.separateLinks}
            className="font-bold"
          />
          <div>{item.area}</div>
          <div>{item.studyType}</div>
          <div>{item.score}</div>
        </div>
      )}
    </Section>
  );
};

const Awards = () => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.awards);

  return (
    <Section section={section} urlKey="url" dateKey="date" summaryKey="summary">
      {(item) => (
        <div>
          <div className="font-bold">{item.title}</div>
          <LinkedEntity name={item.awarder} url={item.url} separateLinks={section?.separateLinks} />
        </div>
      )}
    </Section>
  );
};

const Certifications = () => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.certifications);

  return (
    <Section section={section} urlKey="url" dateKey="date" summaryKey="summary">
      {(item) => (
        <div>
          <div className="font-bold">{item.name}</div>
          <LinkedEntity name={item.issuer} url={item.url} separateLinks={section?.separateLinks} />
        </div>
      )}
    </Section>
  );
};

const Skills = () => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.skills);

  return (
    <Section section={section} levelKey="level" keywordsKey="keywords">
      {(item) => (
        <div>
          <div className="font-bold">{item.name}</div>
          <div>{item.description}</div>
        </div>
      )}
    </Section>
  );
};

const Interests = () => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.interests);

  return (
    <Section section={section} keywordsKey="keywords">
      {(item) => <div className="font-bold">{item.name}</div>}
    </Section>
  );
};

const Publications = () => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.publications);

  return (
    <Section section={section} urlKey="url" dateKey="date" summaryKey="summary">
      {(item) => (
        <div>
          <LinkedEntity
            name={item.name}
            url={item.url}
            separateLinks={section?.separateLinks}
            className="font-bold"
          />
          <div>{item.publisher}</div>
        </div>
      )}
    </Section>
  );
};

const Volunteer = () => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.volunteer);

  return (
    <Section section={section} urlKey="url" dateKey="date" summaryKey="summary">
      {(item) => (
        <div>
          <LinkedEntity
            name={item.organization}
            url={item.url}
            separateLinks={section?.separateLinks}
            className="font-bold"
          />
          <div>{item.position}</div>
          <div>{item.location}</div>
        </div>
      )}
    </Section>
  );
};

const Languages = () => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.languages);

  return (
    <Section section={section} levelKey="level">
      {(item) => (
        <div>
          <div className="font-bold">{item.name}</div>
          <div>{item.description}</div>
        </div>
      )}
    </Section>
  );
};

const Projects = () => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.projects);

  return (
    <Section
      section={section}
      urlKey="url"
      dateKey="date"
      summaryKey="summary"
      keywordsKey="keywords"
    >
      {(item) => (
        <div>
          <LinkedEntity
            name={item.name}
            url={item.url}
            separateLinks={section?.separateLinks}
            className="font-bold"
          />
          <div>{item.description}</div>
        </div>
      )}
    </Section>
  );
};

const References = () => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.references);

  return (
    <Section section={section} urlKey="url" summaryKey="summary">
      {(item) => (
        <div>
          <LinkedEntity
            name={item.name}
            url={item.url}
            separateLinks={section?.separateLinks}
            className="font-bold"
          />
          <div>{item.description}</div>
        </div>
      )}
    </Section>
  );
};

const Custom = ({ id }) => {
  const section = useResumeStore((state) => state?.resume?.data?.sections?.custom[id]);

  return (
    <Section
      section={section}
      urlKey="url"
      dateKey="date"
      summaryKey="summary"
      keywordsKey="keywords"
    >
      {(item) => (
        <div>
          <LinkedEntity
            name={item.name}
            url={item.url}
            separateLinks={section?.separateLinks}
            className="font-bold"
          />
          <div>{item.description}</div>
          <div>{item.location}</div>
        </div>
      )}
    </Section>
  );
};

const mapSectionToComponent = (section) => {
  switch (section) {
    case "profiles": {
      return <Profiles />;
    }
    case "summary": {
      return <Summary />;
    }
    case "experience": {
      return <Experience />;
    }
    case "education": {
      return <Education />;
    }
    case "awards": {
      return <Awards />;
    }
    case "certifications": {
      return <Certifications />;
    }
    case "skills": {
      return <Skills />;
    }
    case "interests": {
      return <Interests />;
    }
    case "publications": {
      return <Publications />;
    }
    case "volunteer": {
      return <Volunteer />;
    }
    case "languages": {
      return <Languages />;
    }
    case "projects": {
      return <Projects />;
    }
    case "references": {
      return <References />;
    }
    default: {
      if (section.startsWith("custom.")) return <Custom id={section.split(".")[1]} />;

      return null;
    }
  }
};

export const Nosepass = ({ columns, isFirstPage = false }) => {
  const name = useResumeStore((state) => state?.resume?.data?.basics?.name);

  const [main, sidebar] = columns;

  return (
    <div className="p-custom space-y-6">
      <div className="flex items-center justify-between">
        <img alt="Europass Logo" className="h-[42px]" src="/assets/europass.png" />

        <p className="font-medium text-primary-css">Curriculum Vitae</p>

        <p className="font-medium text-primary-css">{name}</p>
      </div>

      {isFirstPage && <Header />}

      <div className="space-y-4">
        {main.map((section) => (
          <Fragment key={section}>{mapSectionToComponent(section)}</Fragment>
        ))}

        {sidebar.map((section) => (
          <Fragment key={section}>{mapSectionToComponent(section)}</Fragment>
        ))}
      </div>
    </div>
  );
};
