import { pageSizeMap } from "../utils";
import { cn } from "src/lib/utils";
import { useResumeStore } from "../resume";
import { useEffect, useMemo } from "react";

export const MM_TO_PX = 3.78;

export const Page = ({ mode = "preview", pageNumber, children }) => {
  const page = useResumeStore((state) => state?.resume?.data?.metadata?.page);
  const typography = useResumeStore((state) => state?.resume?.data?.metadata?.typography);
  const metadata = useResumeStore((state) => state?.resume?.data?.metadata);

  const computedStyles = useMemo(() => {
    return {
      "--margin": `${metadata.page.margin}px`,
      "--font-size": `${metadata.typography.font.size}px`,
      "--line-height": `${metadata.typography.lineHeight}`,
      "--color-foreground": metadata.theme.text,
      "--color-primary": metadata.theme.primary,
      "--color-background": metadata.theme.background,
      fontFamily: `"${typography.font.family}", sans-serif`, // Add fallback font
      fontSize: `${metadata.typography.font.size}px`,
      lineHeight: `${metadata.typography.lineHeight}`,
      width: `${pageSizeMap[page.format].width * MM_TO_PX}px`,
      minHeight: `${pageSizeMap[page.format].height * MM_TO_PX}px`,
    };
  }, [metadata, typography, page]);

  useEffect(() => {
    const elements = Array.from(document.querySelectorAll(`[data-page]`));

    for (const el of elements) {
      el.classList.toggle("hide-icons", metadata?.typography?.hideIcons);
      el.classList.toggle("underline-links", metadata?.typography?.underlineLinks);
    }
  }, [metadata]);

  return (
    <div
      data-page={pageNumber}
      className={cn(
        "relative bg-background-css text-foreground-css font-loading-transition",
        mode === "builder" && "shadow-2xl"
      )}
      style={computedStyles}
    >
      {children}

      {mode === "builder" && page.options.breakLine && (
        <div
          className="absolute inset-x-0 border-b border-dashed"
          style={{
            top: `${pageSizeMap[page.format].height * MM_TO_PX}px`,
          }}
        />
      )}
    </div>
  );
};
