import { format } from "date-fns";
import React from "react";
import { useMutation } from "@apollo/client";
import MarkdownRender from "./../../MarkdownRender";
import { READ_NOTIFICATION } from "./queries";

const NotificationCard = ({ notification }) => {
  const [read, { loading: marking }] = useMutation(READ_NOTIFICATION);

  return (
    <div className="flex flex-col gap-3 p-4 border rounded-md shadow">
      <div title={notification.subject} className="text-lg font-medium">
        {notification.subject}
      </div>
      <div title={notification.message}>
        <MarkdownRender className="prose-sm prose sm:prose-base">
          {notification.message}
        </MarkdownRender>
      </div>
      <div className="flex justify-between text-sm">
        <div className="">
          {format(new Date(notification.created_at), "Pp")}
        </div>
        <div>
          <button
            className={`px-4 py-2 rounded ${
              notification.isRead ? "bg-green-500" : "bg-blue-500"
            } text-white flex items-center gap-2`}
            onClick={(e) => {
              e.preventDefault();
              read({ variables: { id: notification.id } });
            }}
          >
            {marking ? (
              "Please wait..."
            ) : notification.isRead ? (
              <>
                <span>Seen</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </>
            ) : (
              <>
                <span>Mark as read</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm-1-9V7a1 1 0 112 0v2h2a1 1 0 110 2h-2v2a1 1 0 11-2 0v-2H7a1 1 0 110-2h2z"
                    clipRule="evenodd"
                  />
                </svg>
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
export default NotificationCard;
