import { createId } from "@paralleldrive/cuid2";
// import { removeItemInLayout } from "@reactive-resume/utils";
import _set from "lodash.set";
import { temporal, TemporalState } from "zundo";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { defaultSection } from "./schemas";
import { debouncedPost } from "src/pages/builder/update";

export const useResumeStore = create(
  temporal(
    immer((set) => ({
      resume: {}, // Initial resume object

      // Actions
      setValue: (path, value) => {
        set((state) => {
          if (path === "visibility") {
            state.resume.visibility = value;
          } else {
            state.resume.data = _set(state.resume.data, path, value);
          }

          void debouncedPost(JSON.parse(JSON.stringify(state.resume)));

        });
      },

      addSection: () => {
        const section = {
          ...defaultSection,
          id: createId(),
          name: `Custom Section`,
          items: [],
        };

        set((state) => {
          const lastPageIndex = state.resume.data.metadata.layout.length - 1;
          state.resume.data.metadata.layout[lastPageIndex][0].push(`custom.${section.id}`);
          state.resume.data = _set(state.resume.data, `sections.custom.${section.id}`, section);
          void debouncedPost(JSON.parse(JSON.stringify({ resume:state.resume, user_id: state.resume.data.userId })));
        });
      },

      removeSection: (sectionId) => {
        if (sectionId.startsWith("custom.")) {
          const id = sectionId.split("custom.")[1];

          set((state) => {
            // removeItemInLayout(sectionId, state.resume.data.metadata.layout);
            delete state.resume.data.sections.custom[id]; // Remove section from store
            void debouncedPost(JSON.parse(JSON.stringify(state.resume)));
          });
        }
      },
    })),
    {
      limit: 100,
      wrapTemporal: (fn) => devtools(fn),
      partialize: ({ resume }) => ({ resume }),
    }
  )
);

export const useTemporalResumeStore = (selector, equality) =>
  useStoreWithEqualityFn(useResumeStore.temporal, selector, equality);
