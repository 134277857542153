// Remove the apollo-boost import and change to this
// Setup the network "links"
import { useMutation } from "@apollo/client";
import {
  addSeconds,
  differenceInSeconds,
  format,
  formatDistanceToNowStrict,
} from "date-fns";
import gql from "graphql-tag";
import {
  lazy,
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Toaster } from "react-hot-toast";
import Modal from "react-modal";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useInterval } from "react-use";
import AuthModal, { AuthModalContext } from "src/components/authModal";
import { FirebaseContext } from "src/firebase";
import Home from "src/pages/home";
import NotFound from "src/pages/not_found";
import SignInViaToken from "./pages/signInViaToken";
import { SidebarProvider } from "./components/ui/sidebar";
import { Print } from "./pages/builder/print";

const ViewCourse = lazy(() => import("./pages/course/view_course"));
const Landing = lazy(() => import("./pages/landing/landing"));
const LandingSwitch = lazy(() => import("./pages/landing"));
const Onboarding = lazy(() => import("./pages/onboarding"));

function CheckSystemTime({ id }) {
  const [currentServerTime, setCurrentServerTime] = useState(null);
  const [loading, setLoading] = useState(true);

  const [update] = useMutation(
    gql`
      mutation GetServerTime($id: uuid!) {
        update_users_by_pk(
          pk_columns: { id: $id }
          _set: { last_online: "now()" }
        ) {
          id
          last_online
        }
      }
    `,
    {
      variables: {
        id,
      },
      onCompleted: (data) => {
        setCurrentServerTime(data.update_users_by_pk.last_online);
        if (loading) setLoading(false);
      },
    }
  );

  useInterval(() => {
    if (id) {
      update();
    }
  }, 30000);

  useEffect(() => {
    if (id) {
      update();
    }
  }, [id, update]);

  const difference = useMemo(() => {
    if (currentServerTime) {
      return Math.abs(
        differenceInSeconds(new Date(currentServerTime), new Date())
      );
    }
    return 0;
  }, [currentServerTime]);

  if (loading || difference < 180) return null;
  return (
    <Modal
      isOpen={true}
      onRequestClose={() => {}}
      htmlOpenClassName="overflow-hidden"
      bodyOpenClassName="overflow-hidden"
      closeTimeoutMS={300}
      className="inset-x-auto inset-y-auto flex flex-col max-h-full rounded-md focus:outline-none"
      overlayClassName="transition-all ease-in-out duration-300 flex justify-center items-center bg-opacity-75 bg-black inset-0 fixed p-8 z-50"
    >
      <div className="flex flex-col w-64 max-h-full px-5 py-6 overflow-y-auto bg-white sm:px-12 md:w-110 rounded-b-md">
        <div className=""></div>
        <div className="w-full text-xl font-semibold text-center">
          Invalid System Time
        </div>
        <div className="text-sm text-center">
          (Please check your system time and make sure it's set correctly)
        </div>
        {currentServerTime && (
          <div className="flex flex-col mt-5 text-sm">
            <div>
              Server Time:{" "}
              <span className="font-semibold">
                {format(new Date(currentServerTime), "Ppp")}
              </span>
            </div>
            <div>
              System time:{" "}
              <span className="font-semibold">{format(new Date(), "Ppp")}</span>
            </div>
            <div>
              Found difference of{" "}
              <span className="font-semibold">
                {formatDistanceToNowStrict(addSeconds(new Date(), difference))}
              </span>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}
// TODO Make config in University table to turn on or turn off few feature
function App({ token, pending, setPending, setCurrentUser, tokenRef }) {
  const { currentUser: currentUserData } = useContext(FirebaseContext);

  if (pending) {
    return (
      <div className="flex items-center justify-center w-screen min-h-screen text-2xl font-semibold">
        <div className="w-6 h-6 spinner-grow" />
        <div className="ml-3">Loading</div>
      </div>
    );
  }

  return (
    //firebase auth

    <>
      {token && <CheckSystemTime id={currentUserData?.id} />}
      <ScrollToTop />
      <Suspense
        fallback={
          <div className="flex items-center justify-center w-full min-h-[500px] text-2xl font-semibold">
            <div className="w-6 h-6 spinner-grow" />
            <div className="ml-3">Loading</div>
          </div>
        }
      >
        <Routes>
          <Route path="/signInViaToken" element={<SignInViaToken />} />
          {currentUserData && !currentUserData.onboarding_complete ? (
            <Route path="/*" element={<Navigate to={`welcome`} />} />
          ) : null}
          <Route path="/" element={<LandingSwitch tokenRef={tokenRef} />} />
          <Route
            path="/auth"
            element={
              currentUserData?.id ? (
                <Navigate to={"/home"} />
              ) : (
                <Landing tokenRef={tokenRef} />
              )
            }
          />
          <Route path="welcome" Component={Onboarding} />
          <Route
            path="home/*"
            element={currentUserData?.id ? <Home /> : <Navigate to={"/auth"} />}
          />
          <Route
            path="/resume/preview/:participant_id/*"
            element={<Print/>}
          />
          <Route
            path={`course/:course_id/:participant_id/*`}
            element={
              <SidebarProvider className="w-full h-full">
                {currentUserData?.id ? (
                  <ViewCourse />
                ) : (
                  <Navigate to={"/auth"} />
                )}
              </SidebarProvider>
            }
          />
          <Route path="*" Component={NotFound} />
        </Routes>
      </Suspense>
      <AuthModalContext.Consumer>
        {({ handleAuthModal, setIsSigningIn, authModalState, isSigningIn }) => (
          <AuthModal
            handleAuthModal={handleAuthModal}
            setIsSigningIn={setIsSigningIn}
            open={authModalState.open}
            type={authModalState.type}
            isSigningIn={isSigningIn}
          />
        )}
      </AuthModalContext.Consumer>
      <Toaster />
    </>
  );
}
export default App;

// {
//      test:true,
//      hackerrank:true,
//      courses:true,
//      events:true,
//      challenges:true,
//      explore:true,
//      projects:true,
//      navheadertype:1
// }
